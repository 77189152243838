.blk-service-icon-slider {

	&__slide-container {
		margin-left: inherit;
	}

	&__slide-img {
		height: rem-calc(82);
	}

	&__slide-caption {
		margin-top: rem-calc(20);
		color: $medium-gray;
	}

	&__cta {
		margin-top: 2rem;
		font-size: $font-18;
		min-width: rem-calc(220);
		@include shadow-gray;
	}

	&--rooms {
		border: rem-calc(1) solid $primary-color;
		@include shadow-gray;

		.orbit-previous,
		.orbit-next {
			top: 0.9rem;
			transform: none;
			color: $primary-color;
		}

		.orbit-bullets {

			button {
				width: rem-calc(10);
				height: rem-calc(10);
				border: rem-calc(1) solid $primary-color;
				background-color: transparent;

				&.is-active {
					background-color: $primary-color;
				}
			}
		}

		.blk-service-icon-slider {
			
			&__img-wrap {
				@include ratio_68;
				position: relative;
				overflow: hidden;

				@include breakpoint(medium) {
					@include ratio_83;		
				}
			}

			&__slide-img {
					height: auto;
			}

			&__slide-img {
				@include absolute_XY_center;
				@include img_cover;
				width: auto;
			}

			&__title {
				margin-bottom: 0;
				font-size: $font-24;
			}

			&__price {
				color: $primary-color;
				margin-bottom: 1rem;
				display: block;
				font-size: $font-16;
			}

			&__text {
				font-size: $font-16;
			}
		}		
	}
}