.blk-footer {
	&__wrap {
		background-color: $black;
	}

	&__newsletter {
		background-color: $dark-gray;
		padding-top: rem-calc(26);
		padding-bottom: rem-calc(26);
	}

	&__main {
		background-color: $black;
		padding-top: rem-calc(26);
		padding-bottom: rem-calc(26);
		color: $primary-color;

		@include breakpoint(medium) {
			padding-bottom: rem-calc(52);
		}
	}

	&__footer-menu {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;

		@include breakpoint(medium) {
			display: flex;
			width: 100%;
			text-align: center;
		}
	}

	&__footer-menu-list {

		@include breakpoint(medium) {
			display: flex;
			flex-grow: 1;
		}
	}

	&__footer-menu-list-item {
		color: $white;
		opacity: 0.5;
		padding-top: rem-calc(15);
		padding-bottom: rem-calc(15);
		display: inline-block;

		&:hover,
		&.selected {
			opacity: 1;
		}

		@include breakpoint(medium) {
			margin: auto;
		}
	}

	&__bottom {
		background-color: $white;
		padding-top: rem-calc(26);
		padding-bottom: rem-calc(10);
	}

	&__logos {
		margin-bottom: 1rem;
	}

	&__newsletter-input-field {
		background: transparent;
    color: $white;
    border-color: $primary-color;
	}

	&__newsletter-cta {
		color: $black;
	}

	::placeholder {
	  color: $primary-color;
	  opacity: 0.3; 
	}

	:-ms-input-placeholder {
	  color: $primary-color;
	  opacity: 0.3; 
	}

	::-ms-input-placeholder {
	  color: $primary-color;
	  opacity: 0.3; 
	}

	&__contacts {
		font-size: $font-18;
		display: block;
		padding-top: rem-calc(7);
		padding-bottom: rem-calc(7);
		margin: auto;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	&__social-title {
		font-size: $font-20;
	}

	&__social-icon {
		font-size: $font-48;
		margin-left: rem-calc(5);
		margin-right: rem-calc(5);
	}
}
