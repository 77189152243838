.blk-photo-text {

	&__container {

		.blk-photo-text {

			&__erase-pad {
				padding-left: 0;
				padding-right: 0;

				@include breakpoint(medium) {
					padding-left: rem-calc(40);
					padding-right: rem-calc(40);
				}
			}
		}
	}

	&__item {
		border: rem-calc(1) solid $primary-color;
		@include shadow-gray;

		@include breakpoint(medium) {
			border: none;
			box-shadow: none;
		}
	}

	&__text-container {
		padding: rem-calc(20) rem-calc(20) rem-calc(15);
		border: rem-calc(1) solid $primary-color;

		@include breakpoint(medium) {
			padding: rem-calc(90) rem-calc(40);
			&--left {
				padding-right: rem-calc(42);
				margin-right: rem-calc(-2);
			}

			&--right {
				padding-left: rem-calc(42);
				margin-left: rem-calc(-2);
			}
		}
	}

	&__text {}

	&__image-container {
		@include ratio_83;
		position: relative;
	}

	&__image {
		@include img_cover;
		@include absolute_XY_center;
	}

	&__title {
		line-height: 1.2;
	}

	&__paragraph {

		@include breakpoint(medium) {
			margin-bottom: 2rem;			
		}
	}

	&__cta {
		@include shadow-gray;
	}

	&__small-cta {
		margin-top: 2rem;
	}

	&__desktop-price {
		background-color: rgba(0,0,0,0.8);
		color: $white;
		padding: rem-calc(15);
		@include absolute_top_left;

		.small-text {
			font-size: $font-12;
		}

		.large-text {
			font-size: $font-18;
		}
	}

	.orbit-bullets {
    position: relative;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    text-align: center;
    display: flex;
    width: 100%;
    text-align: center;
	}

	.orbit-bullets button {
    width: auto;
    height: auto;
    margin: 0.1rem 0.5rem;
    border-radius: 0;
    background-color: transparent !important;
    display: inline;
    flex-grow: 1;
    cursor: pointer;
    font-size: $font-22;
    color: $black;
    opacity: 0.5;

    &:hover,
    &.is-active {
    	text-decoration: underline;
    	opacity: 1;
    }
	}
}
