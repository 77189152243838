.blk-hero-slider {
	position: relative;
	overflow: hidden;

	&--ratio {
		@include ratio_148;

		@include breakpoint(medium) {
			@include ratio_49;		
		}
	}
		
	&__hero-logo {
		@include absolute_XY_center;
		max-width: rem-calc(246);
		z-index: 1;

		@include breakpoint(medium) {
			max-width: rem-calc(340);		
		}
	}

	&__hero-title-container {
		@include absolute_XY_center;
		z-index: 1;
		width: 100%;

		@include breakpoint(medium) {
			@include absolute_Y_center_left;
		}
	}

	&__hero-title {
		font-size: $font-38;
		color: $white;
		font-weight: bold;
		text-shadow: rem-calc(1) rem-calc(1) $black;

		@include breakpoint(medium) {
			font-size: $font-48;
		}
	}

	&__hero-subtitle {
		font-size: $font-18;
		color: $white;
		text-shadow: rem-calc(1) rem-calc(1) $black;

		@include breakpoint(medium) {
			font-size: $font-24;
		}
	}

	&__hero-slide {
		@include absolute_XY_center;
		height: 100%;
		width: 100%;
	}

	&__hero-slide-img {
		@include img_cover;
		width: auto;
	}

	&__hero-overlay {
		@include absolute_XY_center;
		width: 100%;
		z-index: 2;
	}

	&__hero-title-small {
		font-size: $font-28;
		color: $white;
	}

	&__hero-cta {
		margin-top: 2rem;
		font-size: $font-18;
		min-width: rem-calc(220);
	}

	.orbit-previous,
	.orbit-next {
		color: $white;
	}

	.orbit-bullets {
		@include absolute_X_center_bottom

		button {
			width: rem-calc(10);
			height: rem-calc(10);
			border: rem-calc(1) solid $white;
			background-color: transparent;

			&.is-active {
				background-color: $white;
			}
		}
	}
}
