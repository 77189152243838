$font-12: rem-calc(12);
$font-14: rem-calc(14);
$font-16: rem-calc(16);
$font-18: rem-calc(18);
$font-20: rem-calc(20);
$font-22: rem-calc(22);
$font-24: rem-calc(24);
$font-28: rem-calc(28);
$font-38: rem-calc(38);
$font-48: rem-calc(48);

.has-title-line {
	@include breakpoint(medium) {
		display: flex;
		position: relative;
	}

	&:after {
		display: none;
		content: "";
		position: relative;
		height: rem-calc(2);
		background-color: $primary-color;
		flex-grow: 1;
		margin-left: rem-calc(30);
		margin-top: rem-calc(34);

		@include breakpoint(medium) {
			display: flex;
		}
	}
}

.has-h1-style {
	font-size: rem-calc(28);

	@include breakpoint(medium) {
		font-size: rem-calc(44);
	}
}

.subtitle {
	color: $primary-color;
}

.uppercase {
	text-transform: uppercase;
}

.more-line-height {
	line-height: 1.75;
}

.larger-text {
	font-size: $font-18;
}