.blk-main-nav {

	&__menu {
		text-align: left;
	}

	&__menu-title {
		border: rem-calc(1) solid $primary-color;
		border-right: none;
		padding: rem-calc(15);
		color: $primary-color;
		font-size: $font-24;
	}

	&__menu-list {
		margin-top: 2rem;
		text-transform: uppercase;

		ul {

			li {

				a {
					color: $black;

					&:hover {
						color: $primary-color;
					}

					&.selected {
						color: $primary-color;
						font-weight: bold;
					}
				}
			}
		}
	}
	
	&__bar {
		position: fixed;
		top: $over-nav-height;
		left: 0;
		width: 100%;
		z-index: 10;
		background-color: $white;
		text-align: left;
		min-height: rem-calc(60);

		&--over-nav {
			background-color: $dark-gray;
			height: $over-nav-height;
			top: 0;
			min-height: $over-nav-height;

			a {
				font-size: $font-14;
				padding-top: calc(25px / 4);
				padding-bottom: calc(25px / 4);
				line-height: 1;
				display: block;
			}
		}
	}

	&__hamburger {
		@include hamburger($primary-color, $dark-gray, 20px, 16px, 2px, 3);
		//margin: rem-calc(14);
		position: absolute;
		left: rem-calc(14);
		top: 50%;
		transform: translateY(-50%);
	}

	&__logo {
		width: rem-calc(132);
		margin: rem-calc(9.5) auto;

		@include breakpoint(medium) {
			width: rem-calc(160);
		}
	}
}

.offCanvasBook {

	min-width: 100%;

	@media (min-width: 980px) {
		min-width: 980px;
	}
}

a[data-lang="it"].it {
	color: $white;
}

a[data-lang="en"].en {
	color: $white;
}

a[data-lang="fr"].fr {
	color: $white;
}

span.span-name {
	display: none;
}

html[lang="it"] {
	
	span.span-name.span-ita {
		display: inline-block;
	}	
}

html[lang="en"] {
	
	span.span-name.span-eng {
		display: inline-block;
	}	
}

html[lang="fr"] {
	
	span.span-name.span-fra {
		display: inline-block;
	}	
}

