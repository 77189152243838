.blk-offers-hp {
	
	&__wrap {
		display: block;
		border: rem-calc(1) solid $primary-color;
		@include shadow-gray;
		padding-top: rem-calc(15);
		padding-bottom: rem-calc(15);
		margin-bottom: 2rem;
	}

	&__title {
		font-size: $font-24;
	}

	&__text {
		font-size: $font-18;
		margin-bottom: rem-calc(10);
	}
}
