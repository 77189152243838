// Positioning
@mixin absolute_XY_center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}

@mixin absolute_X_center_bottom {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
}

@mixin absolute_Y_center_left {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}

@mixin absolute_top_left {
	position: absolute;
	top: 0;
	left: 0;
}

// Ratio
@mixin ratio_148 {
	width: 100%;
	padding-bottom: 148%;
}

@mixin ratio_83 {
	width: 100%;
	padding-bottom: 83%;
}

@mixin ratio_68 {
	width: 100%;
	padding-bottom: 68%;
}

@mixin ratio_49 {
	width: 100%;
	padding-bottom: 49%;
}

// Image sizes
@mixin img_cover {
	object-fit: cover;
	min-width: 100%;
	min-height: 100%;
}

// Shadows
@mixin shadow-gray {
	box-shadow: 0 rem-calc(2) rem-calc(4) $medium-light-gray;
}
