@import 'global/mixins';
@import 'global/typography';

// Global
body {
	overflow-x: hidden;
	text-align: center;

	@include breakpoint(medium) {
		text-align: left;
	}

	*:focus {
		outline: none !important;
	}
}

.is-full-height {
	height: 100% !important;
}

.space-top {
	padding-top: rem-calc(30);
}

.space-bottom {
	padding-bottom: rem-calc(30);
}

.space-top-bottom {
	padding-top: rem-calc(30);
	padding-bottom: rem-calc(30);
}

.button {
	padding: 0.65em 1em;

	&--cta-shadow {
		@include shadow-gray;
	}
}

$over-nav-height: rem-calc(25);

// Components
@import 'components/main-nav';
@import 'components/hero-slider';
@import 'components/service-icon-slider';
@import 'components/photo-text';
@import 'components/offers-hp';
@import 'components/card';
@import 'components/footer';