.blk-card {

	&__container {
		margin-bottom: 2rem;
	}

	&__image-container {
		@include ratio_68;
		position: relative;
		margin-bottom: 2rem;
	}

	&__image {
		@include img_cover;
		@include absolute_XY_center;
	}

	&__text {
		padding-left: rem-calc(15);
		padding-right: rem-calc(15);
	}
}
